import React from "react";
import "./frontpage.scss"
import Layout from "../components/layout/layout";

const Success = () => {

  return (
    <Layout>

      <main>
        <section>

          <h1>Success!</h1>

        </section>
      </main>

    </Layout>
  )
};

export default Success;
